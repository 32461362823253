<template>
  <div class="studio-chart-wrapper" :class="{ 'adjust-height': chartHeight, 'cover-height': !chartHeight }">
    <div v-if="loading">
      <ScreenLoader />
    </div>
    <div v-else>
      <div v-if="sampleError" class="text-center">{{ sampleError }}</div>
      <div v-else>
        <div class='main-chart pa-0'>
          <div class="chart-name-top">
            <h6>{{chartName}}</h6>
          </div>
          <div :ref="chartId"></div>
        </div>
        <v-dialog v-model="isModalOpen" class="multi-chart-preview-div dashboard-chart-preview composition">
          <v-card class="position-relative studio-card studio-custom-card ">
            <v-card-text>
              <div class="modal-switch-div" v-if="chartType === 'Line Plot' || chartType === 'Scatter Plot'">
              <div class="switch-div">
                <label for="">X Axis Scale:</label>
                <v-switch v-model="isModalXLog" color="info" inset :label="xAxisScaleModal" @change="handleModalXScaleToggleChange"
                  hide-details></v-switch>
              </div>
                      <div class="switch-div">
                <label for="">Y Axis Scale:</label>
                <v-switch v-model="isModalYLog" color="info" inset :label="yAxisScaleModal" @change="handleModalYScaleToggleChange"
                  hide-details></v-switch>
              </div>
              </div>
              <div class="popup-top">
                <div class="chart-name-top w-100">
                  <h6>{{chartName}}</h6>
                </div>
                <!-- <img :src="cross" alt="cross" @click="closeModal" class="chart-preview-close-btn"> -->
              </div>
              <div :ref="modalChartId" class="modal-chart-container"></div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist-min";
import ScreenLoader from "../Loaders/ScreenLoader.vue";

import downloadIcon from '../../../assets/Svg/Chart/csv_chart.svg';
import Expand from "../../../assets/Svg/Chart/expand-btn.svg";

export default {
  name: "DynamicChart",
  components: {
    ScreenLoader
  },
  props: {
    chartHeight: {type: Boolean, default: true},
    loading: { type: Boolean, default: true },
    chartName: { type: String, default: "" },
    chartType: { type: String, required: true },
    xAxisData: { type: Array, default: () => [] },
    yAxisData: { type: Array, default: () => [] },
    zAxisData: { type: Array, default: () => [] },
    xColorGroupData: { type: Array, default: [] },
    yColorGroupData: { type: Array, default: [] },
    zColorGroupData: { type: Array, default: [] },
    colorGroupKey: { type: String, default: "" },
    sampleError: { type: String, default: "" },
    markerSizes: Array,
    xAxisDisplayName: { type: String, default: "" },
    yAxisDisplayNames: { type: Array, default: () => [] },
    xAxisCaption: { type: Array, default: () => [] },
    yAxisCaption: { type: Array, default: () => [] },
    xBins: { type: Number },
    yBins: { type: Number },
    xAxisScale: { type: String, default: "linear" },
    yAxisScale: { type: String, default: "linear" },
  },
  data() {
    return {
      chartId: "dynamicChart",
      modalChartId: "modalChart",
      isModalOpen: false,
      hasValidXColorGroupData: false,
      hasValidYColorGroupData: false,
      previousSidebarState: null,
      cross: require("../../../assets/Svg/Chart/chart-cross.svg"),
      xAxisScaleModal: "linear",
      yAxisScaleModal: "linear",
      isModalXLog: false,
      isModalYLog: false,
    };
  },
  watch: {
    xAxisData: "plotChart",
    yAxisData: "plotChart",
    zAxisData: "plotChart",
    xColorGroupData: "plotChart",
    yColorGroupData: "plotChart",
    zColorGroupData: "plotChart",
    chartType: "plotChart",
    xBins: "plotChart",
    yBins: "plotChart",
    xAxisScale: "plotChart",
    yAxisScale: "plotChart",
    xAxisScaleModal: "plotModalChart",
    yAxisScaleModal: "plotModalChart",
    chartHeight: "resizeChart"
  },
  methods: {
    handleModalXScaleToggleChange() {
  this.xAxisScaleModal = this.isModalXLog ? "log" : "linear";
},
    handleModalYScaleToggleChange() {
  this.yAxisScaleModal = this.isModalYLog ? "log" : "linear";
},
    downloadCSV() {
      const rows = [];

      // Add header row
      const headers = [this.xAxisDisplayName, ...this.yAxisDisplayNames].join(",");
      rows.push(headers);

      // Determine the maximum length for iteration
      const maxLength = Math.max(this.xAxisData.length, this.yAxisData.reduce((max, arr) => Math.max(max, arr.length), 0));

      for (let i = 0; i < maxLength; i++) {
        // Get the x value, defaulting to an empty string if undefined
        const x = this.xAxisData[i] !== undefined ? this.xAxisData[i] : "";

        // Collect the y values for this row
        const yValues = this.yAxisData.map(yDataArray => {
          return yDataArray[i] !== undefined ? yDataArray[i] : "";
        });

        // Add data row with x and corresponding y values
        rows.push([x, ...yValues].join(","));
      }

      // Create CSV Blob
      const csvContent = rows.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      // Create a download link
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `${this.chartName || "chart"}.csv`);
      link.style.visibility = "hidden";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    plotChart() {
      if (!this.$refs[this.chartId]) return;

      const traces = this.generateTraces();
      const layout = this.generateLayout();
      const config = this.generateConfig();
      this.resizeChart();
      // Update the layout to apply log or linear scale
      if (this.chartType === "Scatter Plot" || this.chartType === "Line Plot") {
      layout.xaxis.type = this.xAxisScale;
      layout.yaxis.type = this.yAxisScale;
      }
      Plotly.newPlot(this.$refs[this.chartId], traces, layout, config);
      this.setupSidebarStateCheck();
    },
    plotModalChart() {
      if (!this.$refs[this.modalChartId]) return;

      const traces = this.generateTraces();
      const layout = this.generateLayout();
      const config = this.generateConfig();

      this.$nextTick(() => {
        this.resizeModalChart();
        // Update the layout to apply log or linear scale
      if (this.chartType === "Scatter Plot" || this.chartType === "Line Plot") {
      layout.xaxis.type = this.xAxisScaleModal;
      layout.yaxis.type = this.yAxisScaleModal;
      }
        Plotly.newPlot(this.$refs[this.modalChartId], traces, layout, config)
      });
    },

    generateTraces() {
      const traceType = this.getTraceType();
      const traceMode = this.getTraceMode();
      const scatterMarkerSizes = 8;

      this.hasValidXColorGroupData = Object.values(this.xColorGroupData).some(group => Array.isArray(group) && group.length > 0);
      this.hasValidYColorGroupData = Object.values(this.yColorGroupData).some(group => Array.isArray(group) && group.length > 0);

      if (this.chartType === "Histogram") {
        // Handled Histogram separately
        if (this.hasValidXColorGroupData) {
          return Object.keys(this.xColorGroupData).map((key) => {
            const xData = this.xColorGroupData[key];
            const trace = {
              x: xData,
              type: traceType,
              autobinx: false,
              mode: traceMode,
              name: key,
              xbins: { size: this.calculateBinSize(xData, this.xBins) },
              customdata: xData && xData.length > 0
  ? xData.map((value) => {
      const index = this.xAxisData.indexOf(value); // Find the index of x value in the main axis data
      return this.xAxisCaption[index] || "N/A"; // Use the caption corresponding to the index
    })
  : [],

hovertemplate: `
  %{x}  %{y}  %{customdata}
  <extra></extra>
`,

            };
            return trace;
          })
        }
        else {
          return [{
            x: this.xAxisData,
            type: traceType,
            name: this.chartName,
            autobinx: false,
            xbins: { size: this.calculateBinSize(this.xAxisData, this.xBins) },
            customdata: this.xAxisData && this.xAxisData.length > 0
  ? this.xAxisData.map((value) => {
      const index = this.xAxisData.indexOf(value); // Find the index of x value in the main axis data
      return this.xAxisCaption[index] || "N/A"; // Use the caption corresponding to the index
    })
  : [],

hovertemplate: `
  %{x}  %{y}  %{customdata}
  <extra></extra>
`,

          }];
        }
      } else if (this.hasValidXColorGroupData && this.hasValidYColorGroupData) {
        // Generate traces for each pair of xColorGroupData and yColorGroupData
        return Object.keys(this.xColorGroupData).map((key) => {
          const xData = this.xColorGroupData[key];
          let yData = this.yColorGroupData[key];
          const zData = this.zColorGroupData[key];
          const markerSize = (this.chartType === "Scatter Plot") ? scatterMarkerSizes : this.markerSizes;

          // Ensure xData and yData are arrays and have the same length
          if (!Array.isArray(xData) || !Array.isArray(yData) || xData.length !== yData.length) {
            return null;
          }

          yData = yData.map(item => item[0]); // Assuming each item is an array with one value

          const trace = {
            x: xData,
            y: yData,
            z: zData,
            type: traceType,
            mode: traceMode,
            marker: {
              size: markerSize,
            },
            name: key, // Use the key as the legend label

            customdata: xData && xData.length > 0
              ? xData.map((value) => {
                const index = this.xAxisData.indexOf(value); // Find the index of x value in the main axis data
                return this.xAxisCaption[index] || "N/A"; // Use the caption corresponding to the index
              })
              : yData.map((yValue, i) => {
                // Handle Proxy and flattening if needed
                const actualYAxisData = Array.isArray(this.yAxisData) ? this.yAxisData : this.yAxisData.value;  // Adjust if yAxisData is a proxy

                // Flatten if it's a nested array
                const flattenedYAxisData = actualYAxisData.flat();

                // Search for yValue in the flattened data
                const yIndex = flattenedYAxisData.indexOf(yValue);

                // Check if we found the index, otherwise return "N/A"
                const yCaption = (yIndex !== -1) ? this.yAxisCaption[yIndex] : "N/A";

                return yCaption;
              }),

            hovertemplate: `
  %{x}  %{y}  %{customdata}
  <extra></extra>
`,
          };

          if (this.chartType === "Bubble" || this.chartType === "3D Scatter") {
            const maxMarkerSize = Math.max(...this.markerSizes);
            trace.marker = {
              ...trace.marker,
              sizemode: 'area',
              sizeref: 2.0 * maxMarkerSize / (80 * 2), // Adjusted size reference for better fitting
              sizemin: 2,
            };
          }

          if (this.chartType === "2D Histogram") {
            // trace.colorscale= "Blues"
            // trace.reversescale=true
            trace.colorscale = [
              [0.0, 'rgba(247,251,255,255)'],
              [0.25, 'rgba(193,217,237,255)'],
              [0.5, 'rgba(97,167,210,255)'],
              [0.75, 'rgba(24,101,172,255)'],
              [1.0, 'rgba(8,48,108,255)']
            ]
            trace.colorbar = {
              title: {
                text: 'Number of Samples', // Title for the color bar
                side: 'right',
              }
            };
          }

          if (["2D Histogram", "Histogram", "Bubble", "Heatmap"].includes(this.chartType)) {
            trace.autobinx = false;
            trace.autobiny = false;
            trace.xbins = { size: this.calculateBinSize(xData, this.xBins) };
            trace.ybins = { size: this.calculateBinSize(yData, this.yBins) };
          }

          return trace;
        }).filter(trace => trace !== null);
      }
      else {
        return this.yAxisData.map((yData, index) => {
          const xData = this.xAxisData;
          const zData = this.zAxisData;

          // Check if any of the data arrays are invalid
          const isInvalidData = (data) => !data || data.every(value => value === null || value === undefined);

          if (isInvalidData(xData) || isInvalidData(yData)) {
            console.log("Invalid data detected: Chart will not be plotted.");
            return null; // Skip plotting for invalid data
          }

          const markerSize = (this.chartType === "Scatter Plot")
            ? (scatterMarkerSizes)
            : (this.markerSizes);

          const trace = {
            x: xData,
            y: yData,
            z: zData,
            type: traceType,
            mode: traceMode,
            marker: {
              size: markerSize
            },
            name: this.yAxisDisplayNames[index] || `Series ${index + 1}`,
            customdata: xData && xData.length > 0
              ? xData.map((value) => {
                const index = this.xAxisData.indexOf(value); // Find the index of x value in the main axis data
                return this.xAxisCaption[index] || "N/A"; // Use the caption corresponding to the index
              })
              : yData.map((yValue, i) => {
                // Handle Proxy and flattening if needed
                const actualYAxisData = Array.isArray(this.yAxisData) ? this.yAxisData : this.yAxisData.value;  // Adjust if yAxisData is a proxy

                // Flatten if it's a nested array
                const flattenedYAxisData = actualYAxisData.flat();

                // Search for yValue in the flattened data
                const yIndex = flattenedYAxisData.indexOf(yValue);

                // Check if we found the index, otherwise return "N/A"
                const yCaption = (yIndex !== -1) ? this.yAxisCaption[yIndex] : "N/A";

                return yCaption;
              }),

            hovertemplate: `
  %{x}  %{y}  %{customdata}
  <extra></extra>
`,
          };
          if (this.chartType === "Bubble" || this.chartType === "3D Scatter") {
            const maxMarkerSize = Math.max(...this.markerSizes);
            trace.marker = {
              ...trace.marker,
              sizemode: 'area',
              sizeref: 2.0 * maxMarkerSize / (80 * 2), // Adjusted size reference for better fitting
              sizemin: 2,
            };
          }

          if (this.chartType === "2D Histogram") {
            trace.colorscale = [
              [0.0, 'rgba(247,251,255,255)'],
              [0.25, 'rgba(193,217,237,255)'],
              [0.5, 'rgba(97,167,210,255)'],
              [0.75, 'rgba(24,101,172,255)'],
              [1.0, 'rgba(8,48,108,255)']
            ];
            trace.colorbar = {
              title: {
                text: 'Number of Samples', // Title for the color bar
                side: 'right',
              }
            };
          }

          if (["2D Histogram", "Histogram", "Bubble", "Heatmap"].includes(this.chartType)) {
            trace.autobinx = false;
            trace.autobiny = false;
            trace.xbins = { size: this.calculateBinSize(xData, this.xBins) };
            trace.ybins = { size: this.calculateBinSize(yData, this.yBins) };
          }

          // Return the trace if valid, else skip it
          return trace;
        }).filter(trace => trace !== null); // Ensure no null traces are included in the final array
      }
    },
    generateLayout() {
      return {
        // title: this.getLayoutTitle(),
        xaxis: { title: this.xAxisDisplayName },
         yaxis: { 
          title: {
            text: this.chartType === "Histogram" 
              ? "Number of Samples" 
              : this.yAxisDisplayNames.join(", ").replace(/, /g, "<br>"),
            standoff: 5,
          },
          automargin: true,
        },
        margin: {
          l: 50,
        },
        legend: {
          title: {
            text: this.hasValidXColorGroupData && this.hasValidYColorGroupData ? this.colorGroupKey : ''
          }
        },
      };
    },
    generateConfig() {
      return {
        responsive: true,
        displaylogo: false,
        displayModeBar: true,
        modeBarButtonsToRemove: ['lasso2d', 'select2d', 'pan2d',"resetscale","zoom"],
        modeBarButtonsToAdd: [
          {
            name: "Expand",
            icon: {
              svg: `
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <image href="${Expand}" width="24" height="24"/>
                </svg>
              `,
            },
            click: this.openModal,
          },
          {
            name: "Download CSV",
            icon: {
              svg: `
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <image href="${downloadIcon}" width="24" height="24"/>
                </svg>
              `,
            },
            click: this.downloadCSV,
          },
        ],
      };
    },
    getTraceType() {
      switch (this.chartType) {
        case "Line Plot": return "scatter";
        case "Bar Chart": return "bar";
        case "Histogram": return "histogram";
        case "Bubble": return "scatter";
        case "3D Scatter": return "scatter3d";
        case "Scatter Plot": return "scatter";
        case "2D Histogram": return "histogram2d";
        case "Heatmap": return "heatmap";
        case "Treemap": return "treemap";
        default: return "scatter";
      }
    },
    getTraceMode() {
      if (this.chartType === "Bubble") return "markers";
      if (this.chartType === "Scatter Plot") return "markers";
      return undefined;
    },
    getLayoutTitle() {
      const titles = {
        "Line Plot": "Line Plot",
        "Bar Plot": "Bar Plot",
        "Histogram": "Histogram Plot",
        "Bubble": "Bubble Plot",
        "3D Scatter": "3D Scatter Plot",
        "Scatter Plot": "Scatter Plot",
        "2D Histogram": "2D Histogram Plot",
        "Heatmap": "Heatmap Plot",
        "Treemap": "Treemap Plot"
      };
      return titles[this.chartType] || "";
    },
    calculateBinSize(data, bins) {
      const numericData = data.filter(value => typeof value === 'number' && !isNaN(value));
      if (numericData.length === 0) return 1;
      const minValue = Math.min(...numericData);
      const maxValue = Math.max(...numericData);
      return (maxValue - minValue) / bins;
    },
    openModal() {
      this.isModalOpen = true;
      this.$nextTick(this.plotModalChart);
    },
    closeModal() {
      this.isModalOpen = false;
    },
    resizeChart() {
      if (this.$refs[this.chartId]) {
        Plotly.Plots.resize(this.$refs[this.chartId]);
      }
    },
    resizeModalChart() {
      if (this.$refs[this.modalChartId]) {
        Plotly.Plots.resize(this.$refs[this.modalChartId]);
      }
    },
    checkSidebarState() {
      const currentSidebarState = localStorage.getItem('sidebarState');
      if (this.previousSidebarState !== currentSidebarState) {
        this.previousSidebarState = currentSidebarState;
        // Add a small delay before resizing
        // setTimeout(() => {
        this.resizeChart();
        // }, 50);
      }
    },
    setupSidebarStateCheck() {
      this.checkSidebarState();
      this.sidebarStateCheckInterval = setInterval(this.checkSidebarState, 50);
    },
    removeSidebarStateCheck() {
      clearInterval(this.sidebarStateCheckInterval);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.plotChart();
    });
  },
  beforeDestroy() {
    this.removeSidebarStateCheck();
  },
};
</script>