<template>
  <v-breadcrumbs :items="breadcrumbItems" v-if="!hideBreadcrumbs">
    <template v-slot:divider>
      <div class="divider-img">
        <v-img src="../../../assets/Images/breadcrumb-right-con.png" width="6" height="11"></v-img>
      </div>
    </template>
    <template v-slot:prepend>
      <div class="home-img">
        <v-img src="../../../assets/Images/House.png" class="cursor-pointer" width="20" height="20" @click="goToAssets"></v-img>
      </div>
      <div class="divider-img" v-if="isAssetsRoute">
        <v-img src="../../../assets/Images/breadcrumb-right-con.png" width="6" height="11"></v-img>
      </div>
    </template>
    <template v-slot:item="{ item, index }">
      <div class="text-capitalize test-text d-flex align-end"
        v-if="index === breadcrumbItems.length - 1 && !showTestsDropdown && item.title === 'Tests'">
        {{ item.title }}
        <v-icon  width="16" height="16" @click="toggleTestsDropdown">mdi-chevron-down</v-icon>
      </div>
      <div class="text-capitalize test-text" v-else-if="showTestsDropdown && item.title === 'Tests'" ref="dropdownMenu">
        {{ item.title }}
        <v-icon width="16" height="16" @click="toggleTestsDropdown">mdi-chevron-up</v-icon>
        <div class="dropdown-menu" v-if="allTests.length > 0">
          <div class="test-name-div" v-for="(test, index) in allTests" :key="index">
            <div class="breadcrumb-test-name" @click="openTestPage(test, index)"
              :class="{ 'active': activeTestIndex === index }">
              {{ getTestDisplayName(test.testType, test.index) }}
              <span v-if="test.testTemperature && test.testTemperature_UOM">
                ({{ test.testTemperature }} {{ test.testTemperature_UOM }})
              </span>
            </div>
          </div>
        </div>
      </div>
      <router-link class="text-capitalize" @click.native="handleBreadcrumb(item.title)" v-else
        :to="{ path: item.href }">
        {{ item.title }}
      </router-link>
    </template>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout">
      {{ snackbar.message }}
    </v-snackbar>
  </v-breadcrumbs>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "Links",
  data: () => ({
    items: [],
    currentURLSegments: [],
    showTestsDropdown: false,
    tenantId: localStorage.getItem("tenantId"),
    allTests: [],
    snackbar: {
      visible: false,
      message: "",
      color: "success",
      timeout: 3000,
    },
  }),
  computed: {
    ...mapGetters("test", ['activeTestIndex']),
    isAssetsRoute() {
      return this.$route.name !== 'Assets' && this.$route.name !== 'DataManagement' && this.$route.name !== 'FluidsDataStudio' && this.$route.name !== 'Main';
    },
    hideBreadcrumbs() {
      const hiddenRoutes = ['/fluidsdata-studio', '/visualization-&-dashboards'];
      return hiddenRoutes.includes(this.$route.path.toLowerCase());
    },
    breadcrumbItems() {
      const itemsCopy = this.items.slice();
      const assetParam = this.$route.params.asset;
      const fieldParam = this.$route.params.field;
      const wellParam = this.$route.params.well;
      const currentRoute = this.$route.path;

      if (
        currentRoute.includes("/composition") ||
        currentRoute.includes("/dltest") ||
        currentRoute.includes("/ccetest") ||
        currentRoute.includes("/cvdtest") ||
        currentRoute.includes("/separatortest") ||
        currentRoute.includes("/viscositytest")
      ) {
        itemsCopy.push(
          { title: assetParam ? assetParam.replace(/\//g, '-').replace(/ /g, '-') : "Assets", href: `/assets/${assetParam ? assetParam.replace(/\//g, '-').replace(/ /g, '-') : ''}` },
          { title: fieldParam, href: `/assets/${assetParam ? assetParam.replace(/\//g, '-').replace(/ /g, '-') : ''}/${fieldParam ? fieldParam.replace(/\//g, '-').replace(/ /g, '-') : ''}` },
          { title: wellParam, href: `/assets/${assetParam ? assetParam.replace(/\//g, '-').replace(/ /g, '-') : ''}/${fieldParam ? fieldParam.replace(/\//g, '-').replace(/ /g, '-') : ''}/${wellParam ? wellParam.replace(/\//g, '-').replace(/ /g, '-') : ''}` },
          { title: "Samples", href: `/assets/${assetParam ? assetParam.replace(/\//g, '-').replace(/ /g, '-') : ''}/${fieldParam ? fieldParam.replace(/\//g, '-').replace(/ /g, '-') : ''}/${wellParam ? wellParam.replace(/\//g, '-').replace(/ /g, '-') : ''}` },
          { title: "Tests", href: `/assets/${assetParam ? assetParam.replace(/\//g, '-').replace(/ /g, '-') : ''}/${fieldParam ? fieldParam.replace(/\//g, '-').replace(/ /g, '-') : ''}/${wellParam ? wellParam.replace(/\//g, '-').replace(/ /g, '-') : ''}` }
        );
      } else if (wellParam) {
        itemsCopy.push(
          { title: assetParam ? assetParam.replace(/\//g, '-').replace(/ /g, '-') : "Assets", href: `/assets/${assetParam ? assetParam.replace(/\//g, '-').replace(/ /g, '-') : ''}` },
          { title: fieldParam, href: `/assets/${assetParam ? assetParam.replace(/\//g, '-').replace(/ /g, '-') : ''}/${fieldParam ? fieldParam.replace(/\//g, '-').replace(/ /g, '-') : ''}` },
          { title: wellParam, href: `/assets/${assetParam ? assetParam.replace(/\//g, '-').replace(/ /g, '-') : ''}/${fieldParam ? fieldParam.replace(/\//g, '-').replace(/ /g, '-') : ''}/${wellParam ? wellParam.replace(/\//g, '-').replace(/ /g, '-') : ''}` },
        );
      } else if (fieldParam) {
        itemsCopy.push(
          { title: assetParam ? assetParam.replace(/\//g, '-').replace(/ /g, '-') : "Assets", href: `/assets/${assetParam ? assetParam.replace(/\//g, '-').replace(/ /g, '-') : ''}` },
          { title: fieldParam, href: `/assets/${assetParam ? assetParam.replace(/\//g, '-').replace(/ /g, '-') : ''}/${fieldParam ? fieldParam.replace(/\//g, '-').replace(/ /g, '-') : ''}` },
        );
      } else if (assetParam) {
        itemsCopy.push(
          { title: assetParam ? assetParam.replace(/\//g, '-').replace(/ /g, '-') : "Assets", href: `/assets/${assetParam ? assetParam.replace(/\//g, '-').replace(/ /g, '-') : ''}` },
        );
      }
      else {
        let currentPath = "/";

        itemsCopy.push(
          ...this.currentURLSegments
            .filter((segment, index) => index !== 0 || segment.toLowerCase() !== "assets" && segment.toLowerCase() !== "data-management" && segment.toLowerCase() !== "visualization-&-dashboards" && segment.toLowerCase() !== "fluidsdata-studio")  // Remove "assets" if it exists
            .map((segment) => {
              currentPath += `${segment.replace(/\//g, '-').replace(/ /g, '-')}/`;
              let title = segment.charAt(0).toUpperCase() + segment.slice(1);  // Capitalize first letter
              return {
                title,
                href: currentPath,
                icon: "mdi-chevron-down",
              };
            })
        );
      }
      return itemsCopy;
    },
    displayTestType() {
      return function (testType) {
        switch (testType) {
          case 'composition':
            return 'Fluid Composition';
          case 'dltest':
            return 'DL Test';
          case 'ccetest':
            return 'CCE Test';
          case 'cvdtest':
            return 'CVD Test';
          case 'separatortest':
            return 'Separator Test';
          case 'viscositytest':
            return 'Viscosity Test';
          default:
            return testType;
        }
      }
    },
  },
  methods: {
    ...mapActions("test", ['setActiveTestIndex']),
    showSnackbar(message, color = "success") {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.visible = true;
    },
    goToAssets() {
      const lastView = localStorage.getItem('activeMenu');  // Get 'lastView' from localStorage
      const firstSegment = this.currentURLSegments[0];

      // Check if lastView is '/data-exploration'
      if (lastView && lastView.toLowerCase() === "data management") {
        this.$router.push("/data-management");  // Redirect to /data-exploration
      }
      // Check if the first segment of the URL is 'assets'
      else if (firstSegment && firstSegment.toLowerCase() === "assets") {
        this.$router.push("/assets");  // Redirect to /assets
      }
      // Add additional redirection logic here if needed
    },
    updateBreadcrumbs() {
      const currentURL = window.location.pathname;
      this.currentURLSegments = currentURL
        .split("/")
        .filter((segment) => segment !== "");
      let updatedPath = `/${this.currentURLSegments.join("/")}`;
      updatedPath = updatedPath.replace(/\/$/, "");
      window.history.replaceState({}, "", updatedPath);
    },
    toggleTestsDropdown(event) {
      event.stopPropagation();
      this.showTestsDropdown = !this.showTestsDropdown;
    },
    showTests() {
      this.allTests = [];
      const sampleId = localStorage.getItem("sampleId");
      axios
        .get(process.env.VUE_APP_API_URL + `public/api/v1/samples/${sampleId}/tests`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "X-TenantID": this.tenantId,
          },
        })
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            const tests = response.data.data.allTests;

            const testGroups = {};
            tests.forEach(test => {
              if (!testGroups[test.testType]) {
                testGroups[test.testType] = [];
              }
              testGroups[test.testType].push(test);
            });

            Object.keys(testGroups).forEach(testType => {
              const group = testGroups[testType];
              if (group.length > 1) {
                group.forEach((test, index) => {
                  test.index = index + 1;  // Add numbering (1-based index)
                });
              }
            });

            this.allTests = [].concat(...Object.values(testGroups));
          }
          else if (response && response.data && response.data.message) {
            this.showSnackbar(response.data.message, "error");
          }
          else {
            this.showSnackbar("Error in fetching tests", "error");
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("error");
          }
        });
    },

    getTestDisplayName(testType, index) {
      const testTypeDisplay = this.displayTestType(testType);
      return index ? `${testTypeDisplay} (Test #${index})` : testTypeDisplay;
    },

    openTestPage(test, index) {
      this.setActiveTestIndex(index);
      this.showTestsDropdown = false;
      const sampleId = localStorage.getItem("sampleId");
      const testId = test.ID;
      localStorage.setItem("sampleId", sampleId);
      localStorage.setItem("testId", testId);
      const testType = test.testType;

      const routeParams = { ...this.$route.params };
      let newRoute = `/assets/${routeParams.asset ? routeParams.asset.replace(/\//g, '-').replace(/ /g, '-') : ''}/${routeParams.field ? routeParams.field.replace(/\//g, '-').replace(/ /g, '-') : ''}/${routeParams.well ? routeParams.well.replace(/\//g, '-').replace(/ /g, '-') : ''}/${testType ? testType.replace(/\//g, '-').replace(/ /g, '-') : ''}/${testId ? testId.replace(/\//g, '-').replace(/ /g, '-') : ''}`;
      localStorage.setItem("lastView", newRoute);
      this.$router.push(newRoute);
    },
    closeDropdownMenuOnClickOutside(event) {
      if (this.showTestsDropdown) {
        if (this.$refs.dropdownMenu && !this.$refs.dropdownMenu.contains(event.target)) {
          this.showTestsDropdown = false;
        }
      }
    },
    handleBreadcrumb(title) {
      if (title === "Visualization-&-Dashboards") {
        localStorage.removeItem("lastVisitedDashboard")
      }
    }
  },
  watch: {
    $route(to, from) {
      this.updateBreadcrumbs();
    },
    breadcrumbItems: {
      handler(newItems, oldItems) {
        const containsTests = newItems.some(item => item.title === 'Tests');
        if (containsTests) {
          this.showTests();
        }
      },
      deep: true,
      immediate: true
    },
  },
  mounted() {
    document.body.addEventListener("click", this.closeDropdownMenuOnClickOutside);
    this.updateBreadcrumbs();
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.closeDropdownMenuOnClickOutside);
  },
};
</script>